import eventMutations from './mutations/event';
import absMutations from './mutations/abs';
import teacherMutations from './mutations/teacher';

import careerPlanMutations from './mutations/careerPlan';
import userCredentialsMutations from './mutations/userCredentials';

const getAppSheetFileLink = (table, photo) => {
  if (!photo) return "";
  return photo.includes('https://') ? photo
        : `https://www.appsheet.com/template/gettablefileurl?appName=FDMTFront-517073488&tableName=${table}&fileName=${encodeURIComponent(photo)}`;
};

export default {
  ...absMutations,
  ...eventMutations,
  ...teacherMutations,

  ...careerPlanMutations,
  ...userCredentialsMutations,

  upsertSessions(state, data) {
    data.forEach(ns => {
      const idx = state.sessions.findIndex(s => s.id == ns.id);
      if (idx !== -1) {
        state.sessions[idx] = {
          ...state.sessions[idx],
          ...ns,
        }
      } else {
        state.sessions.push(ns);
      }
    });
  },

  receiveProgramUsers(state, data) {
    state.schoolStudents = data.filter(u => u.teacher == null);
  },

  receiveProgram(state, data) {
    const { related_sectors: relatedSectors, professions_by_group: professionsByGroup } = data;  
    const { default: defaultProfessions, faculty: facultyProfessions } = professionsByGroup;
    const allRelatedProfessions = {};

    // default professions
    for (let i = 0; i < defaultProfessions.length; i++) {
      const profession = state.allProfessions.find(p => p.id == defaultProfessions[i].id);
      if (profession) allRelatedProfessions[profession.id] = profession;
    }

    // faculty professions
    for (let i = 0; i < facultyProfessions.length; i++) {
      const profession = state.allProfessions.find(p => p.id == facultyProfessions[i].id);
      if (profession) allRelatedProfessions[profession.id] = profession;
    }

    // related sectors
    for (let i = 0; i < relatedSectors.length; i++) {
      relatedSectors[i] = state.allSectors.find(s => s.id == relatedSectors[i].id);
    }

    state.currProgram = {
      ...data,
      allRelatedProfessions: Object.values(allRelatedProfessions), // All related professions
    };
    state.loadingProgram = false;
  },

  // SLP
  upsertUserProfessions(state, userProfessions) {
    userProfessions.forEach(nup => {
      const obj = state.user.userProfessions.find(up => up.professionId == nup.professionId);
      if (obj) {
        for (const key in nup) {
          obj[key] = nup[key];
        }
      } else {
        state.user.userProfessions.push(nup);
      }
    });
  },
  upsertUserClaims(state, data) {
    data.forEach(uc => {
      const idx = state.user.claims.findIndex(c => c.claimId == uc.claimId);
      if (idx !== -1) {
        state.user.claims.splice(idx, 1, uc);
      } else {
        state.user.claims.push(uc);
      }
    });
  },
  upsertUserSLP(state, data) {
    state.user.slp = { original: "", gpt: "", ...(state.user.slp || {}), ...data };
  },

  // Ack applied event / interested service
  receiveAckAppliedEvent(state, data) {
    state.ackAppliedEvent = data;
  },
  receiveAckInterestedService(state, data) {
    state.ackInterestedService = data;
  },

  receiveOnetimeAccessInfo(state, data) {
    state.onetimeAccessInfo = data;
  },

  receiveAppPublicData(state, data) {
    const { settings, schools, sessions, anchorEventTasks, formQuestions,
            absIntakes, disciplineGroups, disciplineClaims,
            services, teacherResponseOptions, teacherResponseQuestions,
            jobEXes, clients, lessons, electives, cards } = data;
    state.loadingData = false;

    state.allClients = clients;
    state.allCards = cards;
    state.allElectives = electives;
    state.settings = settings;
    state.schools = schools; // High Schools in HK
    state.sessions = sessions; // ABS / Work / jobEX Events
    state.absIntakes = absIntakes; // ABS Intakes & Sessions
    state.services = services; // Services (EconWork / SurveyWork / ...)
    state.anchorEventTasks = anchorEventTasks; // Anchor Event Tasks
    state.formQuestions = formQuestions; // Form questions related to tasks
    state.teacherResponseOptions = teacherResponseOptions; // Options for Teacher Responses
    state.teacherResponseQuestions = teacherResponseQuestions; // Questions for Teacher Responses (service / survey)
    state.disciplineClaims = disciplineClaims;

    state.allDisciplineGroups = disciplineGroups;
    const allDisciplines = [];
    for (const dg of disciplineGroups) {
      for (const { id, name, groupName, disciplineGroupId } of dg.disciplines) {
        allDisciplines.push({ id, name, groupName, disciplineGroupId });
      }
    }
    state.allDisciplines = allDisciplines;

    state.lessons = lessons; // mainly for getting lesson names (jobEX modal etc.)

    // Unversity Student
    state.allJobEXes = jobEXes.map(jobEX => {
      jobEX.relatedProgramNames = jobEX["Programme"] ? jobEX["Programme"].split(/[,;]+/) : [];
      jobEX.relatedProgramIds = jobEX["Portal Program IDs"] ? jobEX["Portal Program IDs"].split(/[ ,;]+/) : [];
      jobEX.homePageProfessionIds = jobEX["Featured Professions"] ? jobEX["Featured Professions"].split(" , ") : [];
      jobEX.homePageProfessionPrefix = jobEX["Featured Profession Prefix"] ? `${jobEX["Featured Profession Prefix"]} ` : ``;
      return jobEX;
    });
  },
  receivePortalData(state, data) {
    const { sectors, segments, professions, professionTabs, disciplines, institutions, programs, step1Questions, } = data;
    state.allSectors = sectors;
    state.allSegments = segments;
    state.allProfessions = professions.map(p => {
      p.relatedDisciplineIds = p.relatedDisciplines.map(id => id.toString());
      p.relatedDisciplines = p.relatedDisciplines.map(disciplineId => disciplines.find(d => d.id.toString() == disciplineId.toString()));
      return p;
    });
    state.professionTabs = professionTabs;
    state.allDisciplines = disciplines.map(d => {
      const relatedPrograms = programs.filter(p => p.disciplines.includes(d.id));
      let relatedElectives = [];
      for (const p of relatedPrograms) {
        relatedElectives = [
          ...relatedElectives,
          ...p.requiredElective1,
          ...p.requiredElective2,
          ...p.requiredElective3,
          ...p.preferredSubjects,
        ];
      }
      d.relatedElectives = [...new Set(relatedElectives)].map(e => (state.allElectives.find(se => se.name == e))).filter(e => !!e);
      return d;
    });

    // Secondary School
    state.step1Questions = step1Questions;

    // Programs
    const findMostOccurringString = (array) => {
      const frequency = {};
      let maxCount = 0, mostOccurringString = '';
    
      // Count the frequency of each string
      array.forEach((string) => {
        frequency[string] = (frequency[string] || 0) + 1;
        if (frequency[string] > maxCount) {
          maxCount = frequency[string];
          mostOccurringString = string;
        }
      });
      return mostOccurringString;
    }
    state.allInstitutions = institutions;
    state.allPrograms = programs.map(p => {
      const institution = institutions.find(inst => inst.id == p.institutionId);
      p.institutionNameShort = institution.nameShort;
      p.label = `${institution.nameShort} ${p.name}`; // used in Mock JUPAS modal (without JUPAS code)
      p.displayName = (p.jupasCode && p.jupasCode != 'null') ? `[${p.jupasCode}] ${institution.nameShort} ${p.name}`
                                                            : `${institution.nameShort} ${p.name}`;
      p.disciplines = p.disciplines.map(disciplineId => disciplines.find(d => d.id.toString() == disciplineId.toString()));
      p.mainDisciplineGroupName = findMostOccurringString(p.disciplines.map(d => d.groupName));
      //p.displayName = `[${institution.nameShort}] ${p.name}`;
      return p;
    });
    state.draftPrograms = state.allPrograms.filter(p => p.status == 'Draft'); // used in Mock JUPAS only
    //state.allPrograms = state.allPrograms.filter(p => p.status != 'Draft');

    state.loadingPortalData = false;

    if (state.user.id) {
      const { likedProfessionIds, likedSectorIds, likedSegmentIds,
              heardOfProfessionIds, notHeardOfProfessionIds,
              step1OrderedProfessionIds, step2DisciplineIds, step3ProgramIds,
              step1OptionIds,  } = state.user;
      
      // User liked records
      state.allProfessions.forEach(p => {
        p.id = p.id.toString();
        p.likedByUser = likedProfessionIds?.includes(p.id);

        // Profession responses
        if (heardOfProfessionIds?.includes(p.id)) p.userResponse = "Yes";
        else if (notHeardOfProfessionIds?.includes(p.id)) p.userResponse = "No";
      });
      state.allSectors.forEach(s => (s.likedByUser = likedSectorIds?.includes(s.id.toString())));
      state.allSegments.forEach(s => (s.likedByUser = likedSegmentIds?.includes(s.id.toString())));

      // Update user choice values (ids => objects)
      // Step 1 / 2 / 3 choices
      state.user.lastSelectedProfessions = step1OrderedProfessionIds?.split(" , ")
                                                .map(id => (state.allProfessions.find(p => p.id == id))).filter(p => !!p) || [];
      state.user.lastSelectedDisciplines = step2DisciplineIds?.split(" , ")
                                                .map(id => (state.allDisciplines.find(d => d.id == id))).filter(d => !!d) || [];
      state.user.lastSelectedPrograms = step3ProgramIds?.split(" , ")
                                                .map(id => (state.allPrograms.find(p => p.id == id))).filter(p => !!p) || [];
      state.user.lastSelectedStep1Options = step1OptionIds?.split(" , ").map(id => {
        for (const question of state.step1Questions) {
          for (const option of question.options) {
            if (option.id == id) return option;
          }
        }
        return null;
      }).filter(o => !!o) || [];
    }
  },
  upsertStep1Questions(state, step1Questions) {
    step1Questions.forEach(q => {
      const idx = state.step1Questions.findIndex(s => s.id == q.id);
      if (idx !== -1) {
        state.step1Questions[idx] = {
          ...state.step1Questions[idx],
          ...q,
        }
      } else {
        state.step1Questions.push(q);
      }
    });
  },

  // USER
  setLoadingUser(state, isLoading) {
    state.loadingUser = isLoading;
  },
  receiveUser(state, user) {
    user.isAdmin = (user.roles || "").includes('admin');
    user.isCamXApplicant = (user.roles || "").includes('camx-applicant');
    user.isSecondaryStudent = (user.roles || "").includes('secondary-school-student');
    user.isUniversityStudent = (user.roles || "").includes('university-student');
    user.isUniversityClient = (user.roles || "").includes('university-client');

    state.user = user;
    state.loadingUser = false;
    if (user.id) {
      const { heardOfProfessionIds, notHeardOfProfessionIds, sessionResponses, formResponses, } = user;

      // Profession Survey
      user.heardOfProfessionIds = heardOfProfessionIds ? heardOfProfessionIds.split(" , ") : [];
      user.notHeardOfProfessionIds = notHeardOfProfessionIds ? notHeardOfProfessionIds.split(" , ") : [];
      state.submittedProfessionResponses = (heardOfProfessionIds || notHeardOfProfessionIds) ? true : false;

      if (user.studyingElectives && !Array.isArray(user.studyingElectives)) {
        user.studyingElectives = user.studyingElectives.split(" , ");
      }

      // For better switching between students & teacher view
      if (state.user.teacher) state.teacher = { ...state.user.teacher };

      // Commit User Session Responses
      if (sessionResponses) this.commit('receiveUserEventResponses', sessionResponses);

      // Commit User Form Responses
      if (formResponses) {
        state.userFormResponses = formResponses;
        state.loadingUserFormResponses = false;
      }
    }
    
  },
  updateUser(state, updateObj) {
    for (const key in updateObj) {
      state.user[key] = updateObj[key];
    }
  },

  // BROWSING HISTORY (PSS)
  addUserBrowsedProfession(state, professionId) {
    state.user.browsedProfessionIds = state.user.browsedProfessionIds.filter(id => id != professionId);
    state.user.browsedProfessionIds.unshift(professionId);
  },
  addUserBrowsedSector(state, sectorId) {
    state.user.browsedSectorIds = state.user.browsedSectorIds.filter(id => id != sectorId);
    state.user.browsedSectorIds.unshift(sectorId);
  },
  addUserBrowsedSegment(state, segmentId) {
    state.user.browsedSegmentIds = state.user.browsedSegmentIds.filter(id => id != segmentId);
    state.user.browsedSegmentIds.unshift(segmentId);
  },

  // EXTRA INFO
  setProfessionExtraInfo(state, { id, extraInfo }) {
    const profession = state.allProfessions.find(p => p.id == id);
    if (profession) {
      profession.extraInfo = extraInfo;
      profession.fetchedExtraInfo = true;
    }
  },
  setSectorExtraInfo(state, { id, extraInfo }) {
    const sector = state.allSectors.find(s => s.id == id);
    if (sector) {
      sector.extraInfo = extraInfo;
      sector.fetchedExtraInfo = true;
    }
  },
}